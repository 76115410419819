export const endpoints = {
  login: '/auth/login',
  changePassword: '/auth/change-password',
  validateAuth: '/auth/validate',
  programmes: '/programmes',
  verifyPayment: '/payment/verify',
  initializePayment: '/payment/initialize',
  payBill: '/payment/pay',
  getBalance: '/payment/balance',
  getAccountStatement: '/payment/account-statement',
  students: '/students',
  uploadPicture: '/students/upload-picture',
  uploadFile: '/students/upload-file',
  submitApplication: '/students/submit-application',
  getBills: '/students/bills',
  currentSemester: '/academics/current-semester',
  semesterRegistration: '/academics/semester-registration',
  courseRegistration: '/academics/course-registration',
  processAdmission: '/academics/process-admission',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',
  staffs: '/staffs',
  newStaff: '/staffs/create',
  staffUpdate: '/staffs/update',
  staffDelete: '/staffs/delete',
  getFaculties: '/faculties',
  createFaculty: '/faculties/create',
  updateFaculty: '/faculties/update',
  deleteFaculty: '/faculties/delete',
  getCourses: '/courses',
  createCourse: '/courses/create',
  editCourse: '/courses/update',
  deleteCourse: '/courses/delete',
  createProgramme: '/programmes/create',
  updateProgramme: '/programmes/update',
  deleteProgramme: '/programmes/delete',
  createBlog: '/blogs/create',
  getOneBlog: '/blogs/:slug',
  editBlog: '/blogs/edit',
  getBlogs: '/blogs',
  deleteBlog: '/blogs/delete',
  createCategory: '/categories/create',
  getCategories: '/categories',
  editCategory: '/categories/update',
  deleteCategory: '/categories/delete',
  createEvent: '/events/create',
  getEvents: '/events',
  editEvent: '/events/edit',
  deleteEvent: '/events/delete',
  createNotification: '/notifications/create',
  getNotifications: '/notifications',
  editNotification: '/notifications/edit',
  deleteNotification: '/notifications/delete',
  createReport: '/reports/create',
  getAllReports: '/reports',
  getOneStudentAllReports: '/reports/student',
  editReport: '/reports/edit',
  closeReport: '/reports/',
  deleteReports: '/reports/delete',
  createReportConversation: '/reportsMsgs/reply',
  getOneReportAllConversations: '/reportsMsgs',
  editReportConversation: '/reportsMsgs/edit',
  deleteReportConversation: '/reportsMsgs/delete',
  joinChat: '/auth/chat-user',
  getAllChats: '/chatmessages',
  getOneUserChatHistory: '/chats/chat-user',
  sendChat: '/chatmessages/reply',
  getAllChatsHistory: '/chats',
  deleteChats: '/chats/user',
  createContactMessage: '/contacts/create',
  getAllContactMessage: '/contacts',
  deleteContactMessage: '/contacts/delete',
  replyContactMessage: '/contacts/reply'
};
