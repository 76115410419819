// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resetPassword_resetContainer__Iu3zh {
  width: 500px;
  margin: 0 auto 35px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/resetPassword.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;AACF","sourcesContent":[".resetContainer {\n  width: 500px;\n  margin: 0 auto 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resetContainer": `resetPassword_resetContainer__Iu3zh`
};
export default ___CSS_LOADER_EXPORT___;
