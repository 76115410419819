// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CoursesFaculties_courseList__74UST {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admission/CoursesFaculties.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,4DAAA;EACA,SAAA;AACF","sourcesContent":[".courseList {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"courseList": `CoursesFaculties_courseList__74UST`
};
export default ___CSS_LOADER_EXPORT___;
