// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Edit_heading__2Waif {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 2px solid var(--background-secondary);
}

.Edit_personalInfo__Y3ZWu, .Edit_editContact__QiVei, .Edit_input__v1Vgu {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 2px solid var(--background-secondary);
  margin-bottom: 20px;
}

.Edit_input__v1Vgu {
  border: none;
  padding: 5px;
  gap: 10px;
  width: 100%;
}

.Edit_editContact__QiVei {
  padding: 0;
}

.Edit_formPersonal__eKpam {
  display: grid;
  padding: 10px;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}

.Edit_grid__H6qGK, .Edit_threeRowGrid__a7hwt {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  width: 100%;
  gap: 24px;
}
@media screen and (max-width: 768px) {
  .Edit_grid__H6qGK, .Edit_threeRowGrid__a7hwt {
    grid-template-columns: 1fr;
  }
}

.Edit_threeRowGrid__a7hwt {
  padding: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .Edit_threeRowGrid__a7hwt {
    grid-template-columns: 1fr;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Account/EditProfile/Edit.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,8BAAA;EACA,aAAA;EACA,oDAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,6CAAA;EACA,mBAAA;AAAF;;AAGA;EAEE,YAAA;EACA,YAAA;EACA,SAAA;EACA,WAAA;AADF;;AAIA;EAEE,UAAA;AAFF;;AAKA;EACE,aAAA;EACA,aAAA;EACA,SAAA;EACA,qCAAA;AAFF;;AAIA;EACE,aAAA;EACA,8BAAA;EACA,oBAAA;EACA,WAAA;EACA,SAAA;AADF;AAGE;EAPF;IAQI,0BAAA;EAAF;AACF;;AAGA;EAEE,aAAA;EACA,kCAAA;EACA,mBAAA;AADF;AAEE;EALF;IAMI,0BAAA;EACF;AACF","sourcesContent":["@import '../../../styles/variables.scss';\n.heading {\n  display: flex;\n  justify-content: space-between;\n  padding: 10px;\n  border-bottom: 2px solid var(--background-secondary);\n}\n\n.personalInfo {\n  display: flex;\n  flex-direction: column;\n  border-radius: 5px;\n  border: 2px solid var(--background-secondary);\n  margin-bottom: 20px;\n}\n\n.input {\n  @extend .personalInfo;\n  border: none;\n  padding: 5px;\n  gap: 10px;\n  width: 100%;\n}\n\n.editContact {\n  @extend .personalInfo;\n  padding: 0;\n}\n\n.formPersonal {\n  display: grid;\n  padding: 10px;\n  gap: 10px;\n  grid-template-columns: repeat(3, 1fr);\n}\n.grid {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  justify-items: start;\n  width: 100%;\n  gap: 24px;\n\n  @media screen and (max-width: $mobile-breakpoint) {\n    grid-template-columns: 1fr;\n  }\n}\n\n.threeRowGrid {\n  @extend .grid;\n  padding: 10px;\n  grid-template-columns: 1fr 1fr 1fr;\n  align-items: center;\n  @media screen and (max-width: $mobile-breakpoint) {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `Edit_heading__2Waif`,
	"personalInfo": `Edit_personalInfo__Y3ZWu`,
	"editContact": `Edit_editContact__QiVei`,
	"input": `Edit_input__v1Vgu`,
	"formPersonal": `Edit_formPersonal__eKpam`,
	"grid": `Edit_grid__H6qGK`,
	"threeRowGrid": `Edit_threeRowGrid__a7hwt`
};
export default ___CSS_LOADER_EXPORT___;
