// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CourseStatsCard_card__QztUO .ant-card-head {
  background: #f7f7f7;
  color: var(--text-maroon);
  font-size: 20px;
}

.CourseStatsCard_stat__p3wzi div:nth-child(1) {
  font-size: 24px;
  font-weight: 600;
}
.CourseStatsCard_stat__p3wzi div:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
}

.CourseStatsCard_statsRow__mglGf {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Card/CourseStatsCard.module.scss"],"names":[],"mappings":"AAEI;EACE,mBAAA;EACA,yBAAA;EACA,eAAA;AADN;;AAOE;EACE,eAAA;EACA,gBAAA;AAJJ;AAME;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AAJJ;;AAQA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AALF","sourcesContent":[".card {\n  :global {\n    .ant-card-head {\n      background: #f7f7f7;\n      color: var(--text-maroon);\n      font-size: 20px;\n    }\n  }\n}\n\n.stat {\n  div:nth-child(1) {\n    font-size: 24px;\n    font-weight: 600;\n  }\n  div:nth-child(2) {\n    font-size: 14px;\n    font-weight: 400;\n    font-style: italic;\n  }\n}\n\n.statsRow {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `CourseStatsCard_card__QztUO`,
	"stat": `CourseStatsCard_stat__p3wzi`,
	"statsRow": `CourseStatsCard_statsRow__mglGf`
};
export default ___CSS_LOADER_EXPORT___;
