// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Event_form__Jan5B {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px auto 10px;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .Event_form__Jan5B {
    margin-top: 20px;
    width: 100%;
  }
}

.Event_grid__ojVxZ, .Event_threeRowGrid__K9t1r {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  width: 100%;
  gap: 24px;
}
@media screen and (max-width: 768px) {
  .Event_grid__ojVxZ, .Event_threeRowGrid__K9t1r {
    grid-template-columns: 1fr;
  }
}

.Event_threeRowGrid__K9t1r {
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .Event_threeRowGrid__K9t1r {
    grid-template-columns: 1fr;
  }
}

.Event_actionButton__tAgyZ {
  width: 200px;
  margin-top: 20px;
  height: 50px;
}

.Event_applicationBtnDiv__nq8wX {
  display: flex;
  gap: 24px;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .Event_applicationBtnDiv__nq8wX {
    flex-direction: column;
    align-items: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Event/Event.module.scss"],"names":[],"mappings":"AAGA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,SAAA;AAFJ;AAII;EARJ;IASQ,gBAAA;IACA,WAAA;EADN;AACF;;AAIA;EACI,aAAA;EACA,8BAAA;EACA,oBAAA;EACA,WAAA;EACA,SAAA;AADJ;AAGI;EAPJ;IAQQ,0BAAA;EAAN;AACF;;AAGA;EAEI,kCAAA;EACA,mBAAA;AADJ;AAGI;EALJ;IAMQ,0BAAA;EAAN;AACF;;AAGA;EACI,YAAA;EACA,gBAAA;EACA,YAAA;AAAJ;;AAGA;EACI,aAAA;EACA,SAAA;EACA,uBAAA;AAAJ;AAEI;EALJ;IAMQ,sBAAA;IACA,mBAAA;EACN;AACF","sourcesContent":["@import '../../styles/variables.scss';\r\n\r\n\r\n.form {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin: 50px auto 10px;\r\n    gap: 20px;\r\n\r\n    @media screen and (max-width: $mobile-breakpoint) {\r\n        margin-top: 20px;\r\n        width: 100%;\r\n    }\r\n}\r\n\r\n.grid {\r\n    display: grid;\r\n    grid-template-columns: 1fr 1fr;\r\n    justify-items: start;\r\n    width: 100%;\r\n    gap: 24px;\r\n\r\n    @media screen and (max-width: $mobile-breakpoint) {\r\n        grid-template-columns: 1fr;\r\n    }\r\n}\r\n\r\n.threeRowGrid {\r\n    @extend .grid;\r\n    grid-template-columns: 1fr 1fr 1fr;\r\n    align-items: center;\r\n\r\n    @media screen and (max-width: $mobile-breakpoint) {\r\n        grid-template-columns: 1fr;\r\n    }\r\n}\r\n\r\n.actionButton {\r\n    width: 200px;\r\n    margin-top: 20px;\r\n    height: 50px;\r\n}\r\n\r\n.applicationBtnDiv {\r\n    display: flex;\r\n    gap: 24px;\r\n    justify-content: center;\r\n\r\n    @media screen and (max-width: $mobile-breakpoint) {\r\n        flex-direction: column;\r\n        align-items: center;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `Event_form__Jan5B`,
	"grid": `Event_grid__ojVxZ`,
	"threeRowGrid": `Event_threeRowGrid__K9t1r`,
	"actionButton": `Event_actionButton__tAgyZ`,
	"applicationBtnDiv": `Event_applicationBtnDiv__nq8wX`
};
export default ___CSS_LOADER_EXPORT___;
