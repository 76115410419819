// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormField_inputField__Y2SnP {
  height: 50px;
}

.FormField_inputButton__jBlFt {
  height: 50px;
}

.FormField_datePicker__4686f {
  height: 50px;
  width: 100%;
}

.ant-select-selector {
  height: 100% !important;
}
.ant-select-selection-item {
  line-height: 40px !important;
}
.ant-form-item-explain-error {
  font-size: 14px;
}

.FormField_selectField__tdCYl {
  width: 100%;
  height: 50px;
}

.FormField_inputButtonCompact__0LlQJ {
  width: 80%;
}
@media screen and (max-width: 768px) {
  .FormField_inputButtonCompact__0LlQJ {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Form/FormField.module.scss"],"names":[],"mappings":"AAIA;EACE,YAHY;AAAd;;AAMA;EACE,YAPY;AAId;;AAMA;EACE,YAXY;EAYZ,WAAA;AAHF;;AAOE;EACE,uBAAA;AAJJ;AAME;EACE,4BAAA;AAJJ;AAME;EACE,eAAA;AAJJ;;AAQA;EACE,WAAA;EACA,YA7BY;AAwBd;;AAQA;EACE,UAAA;AALF;AAOE;EAHF;IAII,WAAA;EAJF;AACF","sourcesContent":["@import '../../styles/variables.scss';\n\n$inputHeight: 50px;\n\n.inputField {\n  height: $inputHeight;\n}\n\n.inputButton {\n  height: $inputHeight;\n}\n\n.datePicker {\n  height: $inputHeight;\n  width: 100%;\n}\n\n:global {\n  .ant-select-selector {\n    height: 100% !important;\n  }\n  .ant-select-selection-item {\n    line-height: 40px !important;\n  }\n  .ant-form-item-explain-error {\n    font-size: 14px;\n  }\n}\n\n.selectField {\n  width: 100%;\n  height: $inputHeight;\n}\n\n.inputButtonCompact {\n  width: 80%;\n\n  @media screen and (max-width: $mobile-breakpoint) {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputField": `FormField_inputField__Y2SnP`,
	"inputButton": `FormField_inputButton__jBlFt`,
	"datePicker": `FormField_datePicker__4686f`,
	"selectField": `FormField_selectField__tdCYl`,
	"inputButtonCompact": `FormField_inputButtonCompact__0LlQJ`
};
export default ___CSS_LOADER_EXPORT___;
