// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageLayout_pageContainer__4mc68 {
  margin: 50px 40px;
}
@media screen and (max-width: 768px) {
  .PageLayout_pageContainer__4mc68 {
    margin: 100px 20px 50px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/PageLayout.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;AADF;AAEE;EAFF;IAGI,uBAAA;EACF;AACF","sourcesContent":["@import '../../styles/variables.scss';\n\n.pageContainer {\n  margin: 50px 40px;\n  @media screen and (max-width: $mobile-breakpoint) {\n    margin: 100px 20px 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": `PageLayout_pageContainer__4mc68`
};
export default ___CSS_LOADER_EXPORT___;
