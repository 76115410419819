export const paths = {
  index: '/',
  dashboard: '/',
  vision: '/vision',
  strategicOutlook: '/strategic-outlook',
  history: '/history',
  facultiesAndCourses: '/faculties-and-courses',
  courseDetails: '/course-details',
  fees: '/fees',
  admissionRequirements: '/admission-requirements',
  apply: '/apply',
  contactUs: '/contact-us',
  contactMessages: '/dashboard/contact-messages',
  viewMessages: '/dashboard/inquiry-message',
  login: '/login',
  privacyPolicy: '/privacy-policy',
  cookiePolicy: '/cookie-policy',
  termsOfUse: '/terms-of-use',
  resetPassword: '/reset-password',
  forgotPassword: '/forgot-password',
  dashboardIndex: '/dashboard',
  dashboardHome: '/dashboard/home',
  preRegistration: '/dashboard/pre-registration',
  enrolments: '/dashboard/enrollments',
  courseHistory: '/dashboard/course-history',
  profile: '/dashboard/profile',
  changePassword: '/dashboard/change-password',
  documents: 'dashboard/documents',
  makePayment: '/dashboard/make-payment',
  accountStatement: '/dashboard/account-statement',
  transcript: '/dashboard/transcript',
  uploadDocuments: '/dashboard/upload',
  applicationProcess: '/dashboard/application-process',
  editProfile: '/dashboard/edit-profile',
  pendingAdmissions: '/dashboard/pending-admissions',
  admissionDetails: '/dashboard/admission-details',
  confirmPayment: '/dashboard/confirm-payment',
  programmesAndCourses: '/dashboard/programmes-and-courses',
  courses: '/dashboard/courses',
  newCourse: '/dashboard/new-course',
  staffs: '/dashboard/staffs',
  newStaff: '/dashboard/create-new-staff',
  editStaff: '/dashboard/edit-staff',
  faculties: '/dashboard/faculties',
  newFaculty: '/dashboard/new-faculty',
  editFaculty: '/dashboard/edit-faculty',
  programmes: '/dashboard/programmes',
  viewProgramme: '/dashboard/view-programme',
  createProgramme: '/dashboard/create-programme',
  editProgramme: '/dashboard/edit-programme',
  editCourse: '/dashboard/edit-course',
  events: '/dashboard/events',
  newEvent: '/dashboard/create-new-event',
  editEvent: '/dashboard/edit-event',
  notifications: '/dashboard/notifications',
  newNotification: '/dashboard/create-new-notification',
  editNotification: '/dashboard/edit-notification',
  tickets: '/dashboard/tickets',
  ticketsList: '/dashboard/support-tickets',
  newTicket: '/dashboard/new-ticket',
  editTicket: '/dashboard/edit-ticket',
  replyReport: '/dashboard/reply-ticket',
  blogs: '/blogs',
  newBlog: '/dashboard/newBlog',
  blogList: '/dashboard/blogList',
  editBlog: '/dashboard/edit-blog',
  categories: '/dashboard/categories',
  newCategory: '/dashboard/new-category',
  editCategory: '/dashboard/edit-category',
  liveChat: '/dashboard/live-chat',
  developer: '/next-apps'
};
