// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardContentLayout_underline__y\\+uLy {
  display: inline-block;
  height: 5px;
  border: none;
  background-color: var(--background-primary);
  width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/DashboardContentLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,WAAA;EACA,YAAA;EACA,2CAAA;EACA,WAAA;AACF","sourcesContent":[".underline {\n  display: inline-block;\n  height: 5px;\n  border: none;\n  background-color: var(--background-primary);\n  width: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"underline": `DashboardContentLayout_underline__y+uLy`
};
export default ___CSS_LOADER_EXPORT___;
