// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavbarBanner_bannerContainer__ilaWr {
  background: #242424;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}
.NavbarBanner_bannerContainer__ilaWr .NavbarBanner_title__iwQu3 {
  display: flex;
}
.NavbarBanner_bannerContainer__ilaWr .NavbarBanner_title__iwQu3 img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
}
.NavbarBanner_bannerContainer__ilaWr .NavbarBanner_title__iwQu3 div {
  font-size: 28px;
}

.NavbarBanner_phoneNumber__QP9a5 {
  color: white;
  text-decoration: none;
}
.NavbarBanner_phoneNumber__QP9a5:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/NavbarBanner.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;AACF;AACE;EACE,aAAA;AACJ;AAAI;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAEN;AAAI;EACE,eAAA;AAEN;;AAGA;EACE,YAAA;EACA,qBAAA;AAAF;AACE;EACE,0BAAA;AACJ","sourcesContent":[".bannerContainer {\n  background: #242424;\n  color: white;\n  padding: 20px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 80px;\n\n  .title {\n    display: flex;\n    img {\n      width: 40px;\n      height: 40px;\n      margin-right: 10px;\n      cursor: pointer;\n    }\n    div {\n      font-size: 28px;\n    }\n  }\n}\n\n.phoneNumber {\n  color: white;\n  text-decoration: none;\n  &:hover {\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerContainer": `NavbarBanner_bannerContainer__ilaWr`,
	"title": `NavbarBanner_title__iwQu3`,
	"phoneNumber": `NavbarBanner_phoneNumber__QP9a5`
};
export default ___CSS_LOADER_EXPORT___;
