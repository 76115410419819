// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Apply_form__WpU31 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px auto 10px;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .Apply_form__WpU31 {
    margin-top: 20px;
    width: 100%;
  }
}

.Apply_grid__zi4Y\\+, .Apply_threeRowGrid__HuIOb {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  width: 100%;
  gap: 24px;
}
@media screen and (max-width: 768px) {
  .Apply_grid__zi4Y\\+, .Apply_threeRowGrid__HuIOb {
    grid-template-columns: 1fr;
  }
}

.Apply_threeRowGrid__HuIOb {
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .Apply_threeRowGrid__HuIOb {
    grid-template-columns: 1fr;
  }
}

.Apply_actionButton__O6XUL {
  width: 200px;
  margin-top: 20px;
  height: 50px;
}

.Apply_applicationBtnDiv__1fF9N {
  display: flex;
  gap: 24px;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .Apply_applicationBtnDiv__1fF9N {
    flex-direction: column;
    align-items: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Admission/Apply/Apply.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,SAAA;AAAF;AAEE;EARF;IASI,gBAAA;IACA,WAAA;EACF;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,oBAAA;EACA,WAAA;EACA,SAAA;AACF;AACE;EAPF;IAQI,0BAAA;EAEF;AACF;;AACA;EAEE,kCAAA;EACA,mBAAA;AACF;AAAE;EAJF;IAKI,0BAAA;EAGF;AACF;;AAAA;EACE,YAAA;EACA,gBAAA;EACA,YAAA;AAGF;;AADA;EACE,aAAA;EACA,SAAA;EACA,uBAAA;AAIF;AAFE;EALF;IAMI,sBAAA;IACA,mBAAA;EAKF;AACF","sourcesContent":["@import '../../../styles/variables.scss';\n.form {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin: 50px auto 10px;\n  gap: 20px;\n\n  @media screen and (max-width: $mobile-breakpoint) {\n    margin-top: 20px;\n    width: 100%;\n  }\n}\n\n.grid {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  justify-items: start;\n  width: 100%;\n  gap: 24px;\n\n  @media screen and (max-width: $mobile-breakpoint) {\n    grid-template-columns: 1fr;\n  }\n}\n\n.threeRowGrid {\n  @extend .grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  align-items: center;\n  @media screen and (max-width: $mobile-breakpoint) {\n    grid-template-columns: 1fr;\n  }\n}\n\n.actionButton {\n  width: 200px;\n  margin-top: 20px;\n  height: 50px;\n}\n.applicationBtnDiv {\n  display: flex;\n  gap: 24px;\n  justify-content: center;\n\n  @media screen and (max-width: $mobile-breakpoint) {\n    flex-direction: column;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `Apply_form__WpU31`,
	"grid": `Apply_grid__zi4Y+`,
	"threeRowGrid": `Apply_threeRowGrid__HuIOb`,
	"actionButton": `Apply_actionButton__O6XUL`,
	"applicationBtnDiv": `Apply_applicationBtnDiv__1fF9N`
};
export default ___CSS_LOADER_EXPORT___;
