// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileNavbar_mobileNavContainer__cctS7 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #242424;
  color: white;
  z-index: 15;
  position: fixed;
  top: 0;
  height: 80px;
  padding: 0 16px;
}

.MobileNavbar_title__StQ23 {
  font-size: 28px;
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/MobileNavbar.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,yBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,MAAA;EACA,YAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".mobileNavContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  background-color: #242424;\n  color: white;\n  z-index: 15;\n  position: fixed;\n  top: 0;\n  height: 80px;\n  padding: 0 16px;\n}\n\n.title {\n  font-size: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileNavContainer": `MobileNavbar_mobileNavContainer__cctS7`,
	"title": `MobileNavbar_title__StQ23`
};
export default ___CSS_LOADER_EXPORT___;
