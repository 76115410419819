// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.changePassword_changePassword__N93kP {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: right;
  border: 2px solid var(--background-secondary);
}

.changePassword_headText__dkw5f {
  background: var(--background-secondary);
  padding: 20px;
  border-radius: 5px;
  font-size: large;
  width: 100%;
}

.changePassword_header__TVxcv {
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 20px;
  background: var(--text-primary);
  color: var(--text-white);
}`, "",{"version":3,"sources":["webpack://./src/pages/Account/ChangePassword/changePassword.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,sBAAA;EACA,6CAAA;AACF;;AAEA;EACE,uCAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,+BAAA;EACA,wBAAA;AACF","sourcesContent":[".changePassword {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  justify-content: right;\n  border: 2px solid var(--background-secondary);\n}\n\n.headText {\n  background: var(--background-secondary);\n  padding: 20px;\n  border-radius: 5px;\n  font-size: large;\n  width: 100%;\n}\n\n.header {\n  border-radius: 5px;\n  display: flex;\n  align-items: center;\n  padding: 20px;\n  background: var(--text-primary);\n  color: var(--text-white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"changePassword": `changePassword_changePassword__N93kP`,
	"headText": `changePassword_headText__dkw5f`,
	"header": `changePassword_header__TVxcv`
};
export default ___CSS_LOADER_EXPORT___;
