// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Documents_card__7tQYY .Documents_rules__4y9Ym {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Documents_fileUploaded__46pp0, .Documents_card__7tQYY {
  padding: 20px;
  border: 1px solid var(--background-secondary);
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}

.Documents_card__7tQYY {
  margin-top: 16px;
  margin-bottom: 30px;
}
.Documents_card__7tQYY .Documents_rules__4y9Ym {
  background: var(--background-blue-gray);
  color: var(--text-blue);
}

.Documents_allFiles__fzpkM {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.Documents_file__CuYlm {
  border: 1px solid var(--background-secondary);
  display: flex;
  justify-content: space-between;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Account/Documents/Documents.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,6CAAA;EACA,4BAAA;EACA,2BAAA;AACF;;AAEA;EAEE,gBAAA;EACA,mBAAA;AAAF;AAEE;EACE,uCAAA;EACA,uBAAA;AAAJ;;AAKA;EACE,qBAAA;EACA,eAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;AAFF;;AAKA;EACE,6CAAA;EACA,aAAA;EACA,8BAAA;EACA,aAAA;AAFF","sourcesContent":["%sharedFlex {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.fileUploaded {\n  padding: 20px;\n  border: 1px solid var(--background-secondary);\n  border-top-right-radius: 7px;\n  border-top-left-radius: 7px;\n}\n\n.card {\n  @extend .fileUploaded;\n  margin-top: 16px;\n  margin-bottom: 30px;\n\n  .rules {\n    background: var(--background-blue-gray);\n    color: var(--text-blue);\n    @extend %sharedFlex;\n  }\n}\n\n.allFiles {\n  list-style-type: none;\n  padding-left: 0;\n  display: flex;\n  flex-direction: column;\n  margin-top: 20px;\n}\n\n.file {\n  border: 1px solid var(--background-secondary);\n  display: flex;\n  justify-content: space-between;\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Documents_card__7tQYY`,
	"rules": `Documents_rules__4y9Ym`,
	"fileUploaded": `Documents_fileUploaded__46pp0`,
	"allFiles": `Documents_allFiles__fzpkM`,
	"file": `Documents_file__CuYlm`
};
export default ___CSS_LOADER_EXPORT___;
