// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Programmes_grid__GlK6n, .Programmes_threeRowGrid__Kqj1J {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  width: 100%;
  gap: 24px;
}
@media screen and (max-width: 768px) {
  .Programmes_grid__GlK6n, .Programmes_threeRowGrid__Kqj1J {
    grid-template-columns: 1fr;
  }
}

.Programmes_threeRowGrid__Kqj1J {
  grid-template-columns: 1fr 1fr 1fr;
}
@media screen and (max-width: 768px) {
  .Programmes_threeRowGrid__Kqj1J {
    grid-template-columns: 1fr;
  }
}

.Programmes_messageBox__XIG0e {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
.Programmes_messageBox__XIG0e .Programmes_receivedMsg__EpRN- {
  background-color: #f1f1f1;
  color: #333;
  padding: 1rem;
  max-width: 500px;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-self: flex-start;
}
.Programmes_messageBox__XIG0e .Programmes_sentMessage__76xpf {
  background-color: #007bff;
  color: #fff;
  padding: 1rem;
  max-width: 500px;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-self: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/pages/Staff/Programmes/Programmes.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,oBAAA;EACA,WAAA;EACA,SAAA;AADF;AAGE;EAPF;IAQI,0BAAA;EAAF;AACF;;AAGA;EAEE,kCAAA;AADF;AAGE;EAJF;IAKI,0BAAA;EAAF;AACF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,WAAA;AAAF;AAEE;EACE,yBAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;EACA,qBAAA;EACA,wCAAA;EACA,oBAAA;EACA,sBAAA;AAAJ;AAIE;EACE,yBAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;EACA,qBAAA;EACA,wCAAA;EACA,oBAAA;EACA,oBAAA;AAFJ","sourcesContent":["@import '../../../styles/variables';\n\n.grid {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  justify-items: start;\n  width: 100%;\n  gap: 24px;\n\n  @media screen and (max-width: $mobile-breakpoint) {\n    grid-template-columns: 1fr;\n  }\n}\n\n.threeRowGrid {\n  @extend .grid;\n  grid-template-columns: 1fr 1fr 1fr;\n\n  @media screen and (max-width: $mobile-breakpoint) {\n    grid-template-columns: 1fr;\n  }\n}\n\n.messageBox {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  width: 100%;\n\n  .receivedMsg {\n    background-color: #f1f1f1;\n    color: #333;\n    padding: 1rem;\n    max-width: 500px;\n    border-radius: 0.5rem;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    display: inline-flex;\n    align-self: flex-start;\n    \n  }\n\n  .sentMessage {\n    background-color: #007bff;\n    color: #fff; \n    padding: 1rem; \n    max-width: 500px;\n    border-radius: 0.5rem; \n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    display: inline-flex;\n    align-self: flex-end;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `Programmes_grid__GlK6n`,
	"threeRowGrid": `Programmes_threeRowGrid__Kqj1J`,
	"messageBox": `Programmes_messageBox__XIG0e`,
	"receivedMsg": `Programmes_receivedMsg__EpRN-`,
	"sentMessage": `Programmes_sentMessage__76xpf`
};
export default ___CSS_LOADER_EXPORT___;
