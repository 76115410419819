// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes Finance_spin__Med3T {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.Finance_loadingIcon__paXUh {
  margin-top: 20px;
  animation: Finance_spin__Med3T 2s linear infinite;
}

.Finance_depositButton__NkfNn {
  width: 150px;
  height: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Finance/Finance.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;EACF;EACA;IACE,yBAAA;EACF;AACF;AAEA;EACE,gBAAA;EACA,iDAAA;AAAF;;AAGA;EACE,YAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;AAAF","sourcesContent":["@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.loadingIcon {\n  margin-top: 20px;\n  animation: spin 2s linear infinite;\n}\n\n.depositButton {\n  width: 150px;\n  height: 50px !important;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingIcon": `Finance_loadingIcon__paXUh`,
	"spin": `Finance_spin__Med3T`,
	"depositButton": `Finance_depositButton__NkfNn`
};
export default ___CSS_LOADER_EXPORT___;
