// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PhoneField_input__-kKCy {
  border-radius: 4px;
}
.PhoneField_input__-kKCy:hover {
  outline: 1px solid var(--color-primary);
}
.PhoneField_input__-kKCy input:focus, .PhoneField_input__-kKCy input:active {
  border-color: var(--color-primary);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(65, 3, 7, 0.31);
  border-inline-end-width: 1px;
}
.PhoneField_input__-kKCy .PhoneField_flag-dropdown__urjsj:hover {
  outline: 1px solid var(--color-primary);
}`, "",{"version":3,"sources":["webpack://./src/components/Form/PhoneField.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,uCAAA;AAEJ;AACI;EAEE,kCAAA;EACA,UAAA;EACA,0CAAA;EACA,4BAAA;AAAN;AAII;EACE,uCAAA;AAFN","sourcesContent":[".input {\n  border-radius: 4px;\n  &:hover {\n    outline: 1px solid var(--color-primary);\n  }\n  input {\n    &:focus,\n    &:active {\n      border-color: var(--color-primary);\n      outline: 0;\n      box-shadow: 0 0 0 2px rgba(65, 3, 7, 0.31);\n      border-inline-end-width: 1px;\n    }\n  }\n  .flag-dropdown {\n    &:hover {\n      outline: 1px solid var(--color-primary);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `PhoneField_input__-kKCy`,
	"flag-dropdown": `PhoneField_flag-dropdown__urjsj`
};
export default ___CSS_LOADER_EXPORT___;
