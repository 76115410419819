// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Landing_events__0Kw1C .Landing_sectionTitle__aKGik, .Landing_approach__\\+4SLV .Landing_sectionTitle__aKGik {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 30px;
  border-top: 5px solid #960524;
  padding-top: 4px;
}

.Landing_events__0Kw1C .Landing_grid__BA9pL, .Landing_approach__\\+4SLV .Landing_grid__BA9pL {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
@media screen and (max-width: 768px) {
  .Landing_events__0Kw1C .Landing_grid__BA9pL, .Landing_approach__\\+4SLV .Landing_grid__BA9pL {
    grid-template-columns: 1fr;
  }
}

.Landing_bodyContainer__1BW9G {
  margin: 50px 60px 100px;
  overflow-x: hidden;
  position: relative;
}
@media screen and (max-width: 768px) {
  .Landing_bodyContainer__1BW9G {
    margin: 50px 10px 100px;
  }
}

.Landing_approach__\\+4SLV {
  margin-top: 60px;
}
.Landing_approach__\\+4SLV .Landing_sectionTitle__aKGik {
  width: 200px;
}
.Landing_approach__\\+4SLV .Landing_grid__BA9pL {
  margin-bottom: 60px;
}

.Landing_events__0Kw1C {
  margin-top: 60px;
}
.Landing_events__0Kw1C .Landing_sectionTitle__aKGik {
  width: 245px;
}
.Landing_events__0Kw1C .Landing_grid__BA9pL {
  margin-bottom: 60px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Landing/Landing.module.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,6BAAA;EACA,gBAAA;AADF;;AAIA;EACE,aAAA;EACA,qCAAA;EACA,cAAA;AADF;AAGE;EALF;IAMI,0BAAA;EAAF;AACF;;AAGA;EACE,uBAAA;EACA,kBAAA;EACA,kBAAA;AAAF;AACE;EAJF;IAKI,uBAAA;EAEF;AACF;;AACA;EACE,gBAAA;AAEF;AADE;EAEE,YAAA;AAEJ;AACE;EAEE,mBAAA;AAAJ;;AAIA;EACE,gBAAA;AADF;AAEE;EAEE,YAAA;AADJ;AAIE;EAEE,mBAAA;AAHJ","sourcesContent":["@import '../../styles/variables.scss';\n\n%sharedSectionTitle {\n  font-size: 32px;\n  font-weight: 600;\n  line-height: 40px;\n  margin-bottom: 30px;\n  border-top: 5px solid #960524;\n  padding-top: 4px;\n}\n\n%sharedGrid {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  grid-gap: 30px;\n\n  @media screen and (max-width: $mobile-breakpoint) {\n    grid-template-columns: 1fr;\n  }\n}\n\n.bodyContainer {\n  margin: 50px 60px 100px;\n  overflow-x: hidden;\n  position: relative;\n  @media screen and (max-width: $mobile-breakpoint) {\n    margin: 50px 10px 100px;\n  }\n}\n\n.approach {\n  margin-top: 60px;\n  .sectionTitle {\n    @extend %sharedSectionTitle;\n    width: 200px;\n  }\n\n  .grid {\n    @extend %sharedGrid;\n    margin-bottom: 60px;\n  }\n}\n\n.events {\n  margin-top: 60px;\n  .sectionTitle {\n    @extend %sharedSectionTitle;\n    width: 245px;\n  }\n\n  .grid {\n    @extend %sharedGrid;\n    margin-bottom: 60px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"events": `Landing_events__0Kw1C`,
	"sectionTitle": `Landing_sectionTitle__aKGik`,
	"approach": `Landing_approach__+4SLV`,
	"grid": `Landing_grid__BA9pL`,
	"bodyContainer": `Landing_bodyContainer__1BW9G`
};
export default ___CSS_LOADER_EXPORT___;
