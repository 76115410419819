// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextEditor_container__h22UI {
  height: 220px;
}

.TextEditor_textEditor__VYziF {
  height: 150px;
}`, "",{"version":3,"sources":["webpack://./src/components/TextEditor/TextEditor.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".container {\n  height: 220px;\n}\n\n.textEditor {\n  height: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TextEditor_container__h22UI`,
	"textEditor": `TextEditor_textEditor__VYziF`
};
export default ___CSS_LOADER_EXPORT___;
