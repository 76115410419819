// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Requirements_requirementList__xIp8N {
  border: 1px solid var(--background-secondary);
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}

.Requirements_asteric__AQO-r {
  color: var(--color-primary);
}

.Requirements_icon__uxw5M {
  margin-right: 5px;
  color: var(--background-success);
}`, "",{"version":3,"sources":["webpack://./src/components/DocsRequirements/Requirements.module.scss"],"names":[],"mappings":"AAAA;EACE,6CAAA;EACA,4BAAA;EACA,2BAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,iBAAA;EACA,gCAAA;AACF","sourcesContent":[".requirementList {\n  border: 1px solid var(--background-secondary);\n  border-top-right-radius: 7px;\n  border-top-left-radius: 7px;\n}\n\n.asteric {\n  color: var(--color-primary);\n}\n\n.icon {\n  margin-right: 5px;\n  color: var(--background-success);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"requirementList": `Requirements_requirementList__xIp8N`,
	"asteric": `Requirements_asteric__AQO-r`,
	"icon": `Requirements_icon__uxw5M`
};
export default ___CSS_LOADER_EXPORT___;
