// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactUs_grid__EYUfD {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
@media screen and (max-width: 768px) {
  .ContactUs_grid__EYUfD {
    grid-template-columns: 1fr;
  }
}

.ContactUs_actionButton__VJCeI {
  width: 200px;
  margin-top: 20px;
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Contact/ContactUs.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;AADF;AAGE;EALF;IAMI,0BAAA;EAAF;AACF;;AAGA;EACE,YAAA;EACA,gBAAA;EACA,YAAA;AAAF","sourcesContent":["@import '../../styles/variables';\n\n.grid {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 24px;\n\n  @media screen and (max-width: $mobile-breakpoint) {\n    grid-template-columns: 1fr;\n  }\n}\n\n.actionButton {\n  width: 200px;\n  margin-top: 20px;\n  height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `ContactUs_grid__EYUfD`,
	"actionButton": `ContactUs_actionButton__VJCeI`
};
export default ___CSS_LOADER_EXPORT___;
