// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CourseSummaryCard_courseTitle__fGc9Q {
  color: var(--text-maroon);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  gap: 4px;
}

.CourseSummaryCard_actionLink__W2DL0 {
  color: var(--text-maroon);
  font-size: 16px;
  text-align: end;
  padding-right: 24px;
  cursor: default;
}
.CourseSummaryCard_actionLink__W2DL0 span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.ant-card-actions {
  background-color: #f7f7f7 !important;
}
.ant-card-body {
  height: calc(100% - 100px) !important;
}

.CourseSummaryCard_courseCard__VkqPN {
  max-width: 400px;
  border-radius: 0 0 8px 8px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Course/CourseSummaryCard.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,QAAA;AACF;;AAEA;EACE,yBAAA;EACA,eAAA;EACA,eAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACE,0BAAA;EACA,eAAA;AACJ;;AAIE;EACE,oCAAA;AADJ;AAGE;EACE,qCAAA;AADJ;;AAKA;EACE,gBAAA;EACA,qCAAA;AAFF","sourcesContent":[".courseTitle {\n  color: var(--text-maroon);\n  font-size: 24px;\n  font-weight: 600;\n  line-height: 24px;\n  display: flex;\n  gap: 4px;\n}\n\n.actionLink {\n  color: var(--text-maroon);\n  font-size: 16px;\n  text-align: end;\n  padding-right: 24px;\n  cursor: default;\n\n  span:hover {\n    text-decoration: underline;\n    cursor: pointer;\n  }\n}\n\n:global {\n  .ant-card-actions {\n    background-color: #f7f7f7 !important;\n  }\n  .ant-card-body {\n    height: calc(100% - 100px) !important;\n  }\n}\n\n.courseCard {\n  max-width: 400px;\n  border-radius: 0 0 8px 8px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"courseTitle": `CourseSummaryCard_courseTitle__fGc9Q`,
	"actionLink": `CourseSummaryCard_actionLink__W2DL0`,
	"courseCard": `CourseSummaryCard_courseCard__VkqPN`
};
export default ___CSS_LOADER_EXPORT___;
