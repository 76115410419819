// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FeesCard_iconCardContainer__OsAXe, .FeesCard_cardContainer__g06Ir {
  text-align: center;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  height: 470px;
}
.FeesCard_iconCardContainer__OsAXe:hover, .FeesCard_cardContainer__g06Ir:hover {
  background: #960524;
  color: white;
  padding-top: 70px;
  transition: padding-top 0.3s ease-out 0s;
}
.FeesCard_iconCardContainer__OsAXe:hover .FeesCard_icon__A4V1P, .FeesCard_cardContainer__g06Ir:hover .FeesCard_icon__A4V1P {
  background: white;
  color: #960524;
  box-shadow: -8px -8px #4c0c1a;
}

.FeesCard_icon__A4V1P {
  width: 64px;
  height: 64px;
  background: #960524;
  color: white;
  transition: all 0.3s ease-out 0s;
  padding: 15px;
  box-shadow: -8px -8px #ebd1d4;
}

.FeesCard_title__0yvRK {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 8px;
}

.FeesCard_subtitle__uRfb9 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  font-style: italic;
}

.FeesCard_description__8WGqO {
  font-size: 14px;
  line-height: 24px;
}

.FeesCard_cardContainer__g06Ir {
  cursor: pointer;
}
.FeesCard_cardContainer__g06Ir .FeesCard_feeLine__JRim1 {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dccacd;
  padding: 16px 0;
}
.FeesCard_cardContainer__g06Ir:hover .FeesCard_feeLine__JRim1 {
  border-top-color: #5d061c;
}`, "",{"version":3,"sources":["webpack://./src/components/Card/IconCard.module.scss","webpack://./src/components/Card/FeesCard.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;EACA,gCAAA;EACA,yCAAA;EACA,aAAA;ACCF;ADCE;EACE,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,wCAAA;ACCJ;ADAI;EACE,iBAAA;EACA,cAAA;EACA,6BAAA;ACEN;;ADGA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EACA,gCAAA;EACA,aAAA;EACA,6BAAA;ACAF;;ADGA;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;ACAF;;ADEA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;ACCF;;ADCA;EACE,eAAA;EACA,iBAAA;ACEF;;AA3CA;EAEE,eAAA;AA6CF;AA3CE;EACE,aAAA;EACA,8BAAA;EACA,6BAAA;EACA,eAAA;AA6CJ;AAzCI;EACE,yBAAA;AA2CN","sourcesContent":[".iconCardContainer {\n  text-align: center;\n  padding: 80px 20px;\n  transition: all ease-in-out 0.3s;\n  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);\n  height: 470px;\n\n  &:hover {\n    background: #960524;\n    color: white;\n    padding-top: 70px;\n    transition: padding-top 0.3s ease-out 0s;\n    .icon {\n      background: white;\n      color: #960524;\n      box-shadow: -8px -8px #4c0c1a;\n    }\n  }\n}\n\n.icon {\n  width: 64px;\n  height: 64px;\n  background: #960524;\n  color: white;\n  transition: all 0.3s ease-out 0s;\n  padding: 15px;\n  box-shadow: -8px -8px #ebd1d4;\n}\n\n.title {\n  font-size: 28px;\n  font-weight: 600;\n  margin-bottom: 8px;\n}\n.subtitle {\n  font-size: 16px;\n  font-weight: 500;\n  margin-bottom: 16px;\n  font-style: italic;\n}\n.description {\n  font-size: 14px;\n  line-height: 24px;\n}\n","@import 'IconCard.module';\n\n.cardContainer {\n  @extend .iconCardContainer;\n  cursor: pointer;\n\n  .feeLine {\n    display: flex;\n    justify-content: space-between;\n    border-top: 1px solid #dccacd;\n    padding: 16px 0;\n  }\n\n  &:hover {\n    .feeLine {\n      border-top-color: #5d061c;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconCardContainer": `FeesCard_iconCardContainer__OsAXe`,
	"cardContainer": `FeesCard_cardContainer__g06Ir`,
	"icon": `FeesCard_icon__A4V1P`,
	"title": `FeesCard_title__0yvRK`,
	"subtitle": `FeesCard_subtitle__uRfb9`,
	"description": `FeesCard_description__8WGqO`,
	"feeLine": `FeesCard_feeLine__JRim1`
};
export default ___CSS_LOADER_EXPORT___;
