// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Profile_iconBox__fjDjC {
  width: 60px;
  height: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
}

.Profile_isCompleted__9lMkh {
  color: var(--white);
  background: var(--background-primary);
}

.Profile_isNotCompleted__kWvMD {
  background: var(--background-secondary);
}

.Profile_img__M58b4 {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid var(--background-secondary);
}

.Profile_cardStudentData__whuo0 {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border: 1px solid var(--background-secondary);
}

.Profile_profileCardFooter__NDeYH, .Profile_personalInfoCardHeader__zqMsd {
  display: flex;
  justify-content: center;
  padding: 20px;
  margin: 0 -24px -24px;
  border: 1px solid var(--background-secondary);
  background: var(--background-grey);
}
.Profile_profileCardFooter__NDeYH div, .Profile_personalInfoCardHeader__zqMsd div {
  color: var(--text-maroon);
  font-weight: bold;
}

.Profile_personalInfoCardHeader__zqMsd {
  justify-content: space-between;
  margin: -24px -24px 0;
  background: var(--black);
  color: var(--white);
}
.Profile_personalInfoCardHeader__zqMsd div {
  color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/pages/Account/Profile/Profile.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;EACA,qCAAA;AACF;;AAEA;EACE,uCAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,6CAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,6CAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,aAAA;EACA,qBAAA;EACA,6CAAA;EACA,kCAAA;AACF;AACE;EACE,yBAAA;EACA,iBAAA;AACJ;;AAGA;EAEE,8BAAA;EACA,qBAAA;EACA,wBAAA;EACA,mBAAA;AADF;AAGE;EACE,mBAAA;AADJ","sourcesContent":[".iconBox {\n  width: 60px;\n  height: 36px;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  border-radius: 5px;\n}\n\n.isCompleted {\n  color: var(--white);\n  background: var(--background-primary);\n}\n\n.isNotCompleted {\n  background: var(--background-secondary);\n}\n\n.img {\n  width: 120px;\n  height: 120px;\n  border-radius: 50%;\n  border: 1px solid var(--background-secondary);\n}\n\n.cardStudentData {\n  display: flex;\n  justify-content: space-between;\n  padding: 5px 10px;\n  border: 1px solid var(--background-secondary);\n}\n\n.profileCardFooter {\n  display: flex;\n  justify-content: center;\n  padding: 20px;\n  margin: 0 -24px -24px;\n  border: 1px solid var(--background-secondary);\n  background: var(--background-grey);\n\n  div {\n    color: var(--text-maroon);\n    font-weight: bold;\n  }\n}\n\n.personalInfoCardHeader {\n  @extend .profileCardFooter;\n  justify-content: space-between;\n  margin: -24px -24px 0;\n  background: var(--black);\n  color: var(--white);\n\n  div {\n    color: var(--white);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconBox": `Profile_iconBox__fjDjC`,
	"isCompleted": `Profile_isCompleted__9lMkh`,
	"isNotCompleted": `Profile_isNotCompleted__kWvMD`,
	"img": `Profile_img__M58b4`,
	"cardStudentData": `Profile_cardStudentData__whuo0`,
	"profileCardFooter": `Profile_profileCardFooter__NDeYH`,
	"personalInfoCardHeader": `Profile_personalInfoCardHeader__zqMsd`
};
export default ___CSS_LOADER_EXPORT___;
