// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar_navbarContainer__3B5\\+n {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  height: 64px;
  background-color: var(--background-primary);
  color: white;
  width: 100%;
}

.Navbar_navActionButtons__aNO5M {
  display: flex;
  gap: 16px;
}
.Navbar_navActionButtons__aNO5M .Navbar_ghost__136z5 {
  border-color: var(--grey);
  color: var(--text-white);
}
.Navbar_navActionButtons__aNO5M .Navbar_ghost__136z5:hover {
  border-color: var(--grey) !important;
  color: var(--text-white) !important;
  background: var(--background-primary-hover) !important;
}

.Navbar_navItemsSection__Pgm53, .Navbar_stickyNavItemsSection__vrspJ {
  display: flex;
  align-items: center;
}
.Navbar_navItemsSection__Pgm53 .Navbar_logo__YJM-Q, .Navbar_stickyNavItemsSection__vrspJ .Navbar_logo__YJM-Q {
  display: none;
}
.Navbar_navItemsSection__Pgm53 .Navbar_navItems__M7MuW, .Navbar_stickyNavItemsSection__vrspJ .Navbar_navItems__M7MuW {
  display: flex;
  gap: 16px;
}

.Navbar_stickyNavItemsSection__vrspJ .Navbar_logo__YJM-Q {
  display: block;
  width: 60px;
  height: 60px;
  margin-right: 20px;
  cursor: pointer;
}

.Navbar_sticky__9AcZ1 {
  background-color: #242424;
  color: white;
  z-index: 3;
  position: fixed;
  top: 0;
  height: 80px;
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Navbar.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,2CAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;AACF;AACE;EACE,yBAAA;EACA,wBAAA;AACJ;AAAI;EACE,oCAAA;EACA,mCAAA;EACA,sDAAA;AAEN;;AAGA;EACE,aAAA;EACA,mBAAA;AAAF;AACE;EACE,aAAA;AACJ;AAEE;EACE,aAAA;EACA,SAAA;AAAJ;;AAME;EACE,cAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AAHJ;;AAOA;EACE,yBAAA;EACA,YAAA;EACA,UAAA;EACA,eAAA;EACA,MAAA;EACA,YAAA;AAJF","sourcesContent":[".navbarContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 40px;\n  height: 64px;\n  background-color: var(--background-primary);\n  color: white;\n  width: 100%;\n}\n\n.navActionButtons {\n  display: flex;\n  gap: 16px;\n\n  .ghost {\n    border-color: var(--grey);\n    color: var(--text-white);\n    &:hover {\n      border-color: var(--grey) !important;\n      color: var(--text-white) !important;\n      background: var(--background-primary-hover) !important;\n    }\n  }\n}\n\n.navItemsSection {\n  display: flex;\n  align-items: center;\n  .logo {\n    display: none;\n  }\n\n  .navItems {\n    display: flex;\n    gap: 16px;\n  }\n}\n\n.stickyNavItemsSection {\n  @extend .navItemsSection;\n  .logo {\n    display: block;\n    width: 60px;\n    height: 60px;\n    margin-right: 20px;\n    cursor: pointer;\n  }\n}\n\n.sticky {\n  background-color: #242424;\n  color: white;\n  z-index: 3;\n  position: fixed;\n  top: 0;\n  height: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbarContainer": `Navbar_navbarContainer__3B5+n`,
	"navActionButtons": `Navbar_navActionButtons__aNO5M`,
	"ghost": `Navbar_ghost__136z5`,
	"navItemsSection": `Navbar_navItemsSection__Pgm53`,
	"stickyNavItemsSection": `Navbar_stickyNavItemsSection__vrspJ`,
	"logo": `Navbar_logo__YJM-Q`,
	"navItems": `Navbar_navItems__M7MuW`,
	"sticky": `Navbar_sticky__9AcZ1`
};
export default ___CSS_LOADER_EXPORT___;
