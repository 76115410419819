// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CourseDetail_container__L4bTf {
  padding: 0 40px;
}
@media screen and (max-width: 768px) {
  .CourseDetail_container__L4bTf {
    padding: 0;
  }
}

.CourseDetail_pageContent__SnVDa {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 24px;
}
@media screen and (max-width: 768px) {
  .CourseDetail_pageContent__SnVDa {
    grid-template-columns: 1fr;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Admission/CourseDetail.module.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;AADF;AAEE;EAFF;IAGI,UAAA;EACF;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;AACF;AAEE;EANF;IAOI,0BAAA;EACF;AACF","sourcesContent":["@import '../../styles/variables';\n\n.container {\n  padding: 0 40px;\n  @media screen and (max-width: $mobile-breakpoint) {\n    padding: 0;\n  }\n}\n\n.pageContent {\n  display: grid;\n  grid-template-columns: 3fr 2fr;\n  gap: 24px;\n  \n\n  @media screen and (max-width: $mobile-breakpoint) {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CourseDetail_container__L4bTf`,
	"pageContent": `CourseDetail_pageContent__SnVDa`
};
export default ___CSS_LOADER_EXPORT___;
