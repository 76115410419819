// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_sidebarContainer__0mn60 {
  grid-area: sidebar;
  background: var(--black);
  color: var(--white);
}
@media screen and (max-width: 768px) {
  .Sidebar_sidebarContainer__0mn60 {
    display: none;
  }
}
.Sidebar_sidebarContainer__0mn60 .Sidebar_icon__O94Zq {
  display: none;
}

.Sidebar_displaySidebar__G9QX- {
  position: absolute;
  top: 15vh;
  left: 0;
  background: var(--black);
  height: 85vh;
  z-index: 2;
  width: 80% !important;
}
.Sidebar_displaySidebar__G9QX- .Sidebar_icon__O94Zq {
  position: absolute;
  display: flex;
  background: white;
  z-index: 3;
}

.Sidebar_sidebarCollapse__fGpAC {
  background: var(--black);
  border: 0;
}
.Sidebar_sidebarCollapse__fGpAC .Sidebar_panel__kqHHI.Sidebar_hover__EV0O9 {
  background: var(--color-primary);
}

.ant-menu {
  width: 100% !important;
}
.ant-menu-submenu-title, .ant-menu-title-content, .ant-menu-item-icon {
  color: var(--white) !important;
}
.ant-menu-item:hover, .ant-menu-submenu-title:hover {
  background: var(--background-primary) !important;
}
.ant-menu-item-selected {
  background: var(--background-primary) !important;
}

.Sidebar_sidebarItem__K6XYJ {
  padding: 4px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/Sidebar.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,wBAAA;EACA,mBAAA;AADF;AAEE;EAJF;IAKI,aAAA;EACF;AACF;AAAE;EACE,aAAA;AAEJ;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACC,wBAAA;EACD,YAAA;EACA,UAAA;EACA,qBAAA;AACF;AAAE;EACE,kBAAA;EACA,aAAA;EACA,iBAAA;EACA,UAAA;AAEJ;;AAGA;EACE,wBAAA;EACA,SAAA;AAAF;AAGI;EACE,gCAAA;AADN;;AAOE;EACE,sBAAA;AAJJ;AAME;EAGE,8BAAA;AAJJ;AAQI;EACE,gDAAA;AALN;AAQE;EACE,gDAAA;AANJ;;AAUA;EACE,uBAAA;AAPF","sourcesContent":["@import '../../styles/variables.scss';\n\n.sidebarContainer {\n  grid-area: sidebar;\n  background: var(--black);\n  color: var(--white);\n  @media screen and (max-width: $mobile-breakpoint) {\n    display: none;\n  }\n  .icon {\n    display: none;\n  }\n}\n\n.displaySidebar {\n  position: absolute;\n  top: 15vh;\n  left: 0;\n   background: var(--black);\n  height: 85vh;\n  z-index: 2;\n  width: 80% !important;\n  .icon {\n    position: absolute;\n    display: flex;\n    background: white;\n    z-index: 3;\n  }\n \n}\n\n.sidebarCollapse {\n  background: var(--black);\n  border: 0;\n\n  .panel {\n    &.hover {\n      background: var(--color-primary);\n    }\n  }\n}\n\n:global {\n  .ant-menu {\n    width: 100% !important;\n  }\n  .ant-menu-submenu-title,\n  .ant-menu-title-content,\n  .ant-menu-item-icon {\n    color: var(--white) !important;\n  }\n  .ant-menu-item,\n  .ant-menu-submenu-title {\n    &:hover {\n      background: var(--background-primary) !important;\n    }\n  }\n  .ant-menu-item-selected {\n    background: var(--background-primary) !important;\n  }\n}\n\n.sidebarItem {\n  padding: 4px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarContainer": `Sidebar_sidebarContainer__0mn60`,
	"icon": `Sidebar_icon__O94Zq`,
	"displaySidebar": `Sidebar_displaySidebar__G9QX-`,
	"sidebarCollapse": `Sidebar_sidebarCollapse__fGpAC`,
	"panel": `Sidebar_panel__kqHHI`,
	"hover": `Sidebar_hover__EV0O9`,
	"sidebarItem": `Sidebar_sidebarItem__K6XYJ`
};
export default ___CSS_LOADER_EXPORT___;
