// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_loginContainer__32cQc {
  width: 350px;
  margin: 0 auto 35px;
}

.Login_forgotPassword__Z5vx1:hover {
  text-decoration: underline;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;AACF;;AAGE;EACE,0BAAA;EACA,eAAA;AAAJ","sourcesContent":[".loginContainer {\n  width: 350px;\n  margin: 0 auto 35px;\n}\n\n.forgotPassword {\n  &:hover {\n    text-decoration: underline;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginContainer": `Login_loginContainer__32cQc`,
	"forgotPassword": `Login_forgotPassword__Z5vx1`
};
export default ___CSS_LOADER_EXPORT___;
