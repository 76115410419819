// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 768px) {
  .AdmissionDetail_personalInfo__A-DCg {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}

.AdmissionDetail_info__Or5wE {
  padding: 20px;
  border-radius: 5px;
}

.AdmissionDetail_image__sgVau {
  width: 100%;
  height: 200px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Staff/Admission/AdmissionDetail.module.scss"],"names":[],"mappings":"AAGE;EADF;IAEI,aAAA;IACA,8BAAA;IACA,mBAAA;EADF;AACF;;AAIA;EACE,aAAA;EACA,kBAAA;AADF;;AAIA;EACE,WAAA;EACA,aAAA;AADF","sourcesContent":["@import '../../../styles/variables.scss';\n\n.personalInfo {\n  @media screen and (max-width: $mobile-breakpoint) {\n    display: flex;\n    flex-direction: column-reverse;\n    align-items: center;\n  }\n}\n\n.info {\n  padding: 20px;\n  border-radius: 5px;\n}\n\n.image {\n  width: 100%;\n  height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"personalInfo": `AdmissionDetail_personalInfo__A-DCg`,
	"info": `AdmissionDetail_info__Or5wE`,
	"image": `AdmissionDetail_image__sgVau`
};
export default ___CSS_LOADER_EXPORT___;
