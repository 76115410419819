// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Explore_container__-mDUC {
  background-color: #f4f4f5;
  margin: 20px -80px 0;
}
@media screen and (max-width: 768px) {
  .Explore_container__-mDUC {
    margin: 0 -20px;
    padding: 20px;
  }
}

.Explore_exploreContainer__O2JQz {
  display: grid;
  padding: 32px;
  grid-template-columns: 600px 1fr;
  gap: 1rem;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  grid-gap: 24px;
}
@media screen and (max-width: 768px) {
  .Explore_exploreContainer__O2JQz {
    grid-template-columns: 1fr;
    padding: 0;
  }
}

.Explore_exploreText__Ts-fJ div:nth-child(1) {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}
.Explore_exploreText__Ts-fJ div:nth-child(2) {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
}

.Explore_imgDiv__3C6XH > img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/Explore/Explore.module.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,oBAAA;AADF;AAEE;EAHF;IAII,eAAA;IACA,aAAA;EACF;AACF;;AAEA;EACE,aAAA;EACA,aAAA;EACA,gCAAA;EACA,SAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;EACA,cAAA;AACF;AACE;EAVF;IAWI,0BAAA;IACA,UAAA;EAEF;AACF;;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AACE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AACJ;;AAIE;EACE,eAAA;EACA,YAAA;EACA,iBAAA;AADJ","sourcesContent":["@import '../../styles/variables';\n\n.container {\n  background-color: #f4f4f5;\n  margin: 20px -80px 0;\n  @media screen and (max-width: $mobile-breakpoint) {\n    margin: 0 -20px;\n    padding: 20px;\n  }\n}\n\n.exploreContainer {\n  display: grid;\n  padding: 32px;\n  grid-template-columns: 600px 1fr;\n  gap: 1rem;\n  align-items: center;\n  max-width: 1200px;\n  margin: 0 auto;\n  grid-gap: 24px;\n\n  @media screen and (max-width: $mobile-breakpoint) {\n    grid-template-columns: 1fr;\n    padding: 0;\n  }\n}\n\n.exploreText {\n  & div:nth-child(1) {\n    font-size: 32px;\n    font-weight: 600;\n    line-height: 40px;\n  }\n  & div:nth-child(2) {\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 24px;\n    margin-top: 16px;\n  }\n}\n\n.imgDiv {\n  & > img {\n    max-width: 100%;\n    height: auto;\n    object-fit: cover;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Explore_container__-mDUC`,
	"exploreContainer": `Explore_exploreContainer__O2JQz`,
	"exploreText": `Explore_exploreText__Ts-fJ`,
	"imgDiv": `Explore_imgDiv__3C6XH`
};
export default ___CSS_LOADER_EXPORT___;
