// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AntTable_table__ays2e {
  border: 1px solid var(--color-offwhite);
  border-radius: 10px;
  border-collapse: collapse;
}
.AntTable_table__ays2e .ant-table-thead > tr > th {
  background-color: var(--black) !important;
  font-size: 16px !important;
  color: var(--white) !important;
}
.AntTable_table__ays2e .ant-table-cell::before {
  background: var(--black) !important;
}
.AntTable_table__ays2e .status-open {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
}
.AntTable_table__ays2e .status-closed {
  background-color: red;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
}
.AntTable_table__ays2e .status-awaiting-reply {
  background-color: blue;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
}
.AntTable_table__ays2e .status-default {
  background-color: lightgray;
  color: black;
  padding: 2px 8px;
  border-radius: 4px;
}
@media (max-width: 768px) {
  .AntTable_table__ays2e .ant-table {
    width: 90vw;
    overflow-x: auto;
    white-space: nowrap;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Table/AntTable.module.scss"],"names":[],"mappings":"AAAA;EACE,uCAAA;EACA,mBAAA;EACA,yBAAA;AACF;AAEI;EACE,yCAAA;EACA,0BAAA;EACA,8BAAA;AAAN;AAGI;EACE,mCAAA;AADN;AAII;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,uBAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AAFN;AAKI;EACE,qBAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AAHN;AAMI;EACE,sBAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AAJN;AAOI;EACE,2BAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AALN;AAQI;EACE;IACE,WAAA;IACA,gBAAA;IACA,mBAAA;EANN;AACF","sourcesContent":[".table {\n  border: 1px solid var(--color-offwhite);\n  border-radius: 10px;\n  border-collapse: collapse;\n\n  :global {\n    .ant-table-thead>tr>th {\n      background-color: var(--black) !important;\n      font-size: 16px !important;\n      color: var(--white) !important;\n    }\n\n    .ant-table-cell::before {\n      background: var(--black) !important;\n    }\n\n    .status-open {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      background-color: green;\n      color: white;\n      padding: 2px 8px;\n      border-radius: 4px;\n    }\n\n    .status-closed {\n      background-color: red;\n      color: white;\n      padding: 2px 8px;\n      border-radius: 4px;\n    }\n\n    .status-awaiting-reply {\n      background-color: blue;\n      color: white;\n      padding: 2px 8px;\n      border-radius: 4px;\n    }\n\n    .status-default {\n      background-color: lightgray;\n      color: black;\n      padding: 2px 8px;\n      border-radius: 4px;\n    }\n\n    @media (max-width: 768px) {\n      .ant-table {\n        width: 90vw;\n        overflow-x: auto;\n        white-space: nowrap;\n      }\n\n    }\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `AntTable_table__ays2e`
};
export default ___CSS_LOADER_EXPORT___;
