// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Partnership_title__CceCu {
  font-size: 46px;
}

.Partnership_subtitle__jh0M5 {
  width: 500px;
  margin: 16px auto;
}
@media screen and (max-width: 768px) {
  .Partnership_subtitle__jh0M5 {
    width: 100%;
  }
}

@keyframes Partnership_slide__pA-9S {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
.Partnership_partners__djouE {
  overflow: hidden;
  margin: 20px 0px;
  display: flex;
  white-space: nowrap;
}

.Partnership_partners__djouE:hover .Partnership_partner__RWoIj {
  animation-play-state: paused;
}

.Partnership_partner__RWoIj {
  display: inline-block;
  animation: 40s Partnership_slide__pA-9S infinite linear;
}

.Partnership_partnersImage__zHRHl {
  height: 90px;
  width: auto;
  margin: 0 50px;
  filter: grayscale(100%);
}
@media screen and (max-width: 768px) {
  .Partnership_partnersImage__zHRHl {
    height: 50px;
  }
}

@keyframes Partnership_slide__pA-9S {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Partnership/Partnership.module.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;AADF;;AAIA;EACE,YAAA;EACA,iBAAA;AADF;AAGE;EAJF;IAKI,WAAA;EAAF;AACF;;AAGA;EACE;IACE,wBAAA;EAAF;EAEA;IACE,4BAAA;EAAF;AACF;AAGA;EACE,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AADF;;AAIA;EACE,4BAAA;AADF;;AAIA;EACE,qBAAA;EACA,uDAAA;AADF;;AAIA;EACE,YAAA;EACA,WAAA;EACA,cAAA;EACA,uBAAA;AADF;AAGE;EANF;IAOI,YAAA;EAAF;AACF;;AAGA;EACE;IACE,wBAAA;EAAF;EAEA;IACE,4BAAA;EAAF;AACF","sourcesContent":["@import '../../styles/variables';\n\n.title {\n  font-size: 46px;\n}\n\n.subtitle {\n  width: 500px;\n  margin: 16px auto;\n\n  @media screen and (max-width: $mobile-breakpoint) {\n    width: 100%;\n  }\n}\n\n@keyframes slide {\n  from {\n    transform: translateX(0);\n  }\n  to {\n    transform: translateX(-100%);\n  }\n}\n\n.partners {\n  overflow: hidden;\n  margin: 20px 0px;\n  display: flex;\n  white-space: nowrap;\n}\n\n.partners:hover .partner {\n  animation-play-state: paused;\n}\n\n.partner {\n  display: inline-block;\n  animation: 40s slide infinite linear;\n}\n\n.partnersImage {\n  height: 90px;\n  width: auto;\n  margin: 0 50px;\n  filter: grayscale(100%);\n\n  @media screen and (max-width: $mobile-breakpoint) {\n    height: 50px;\n  }\n}\n\n@keyframes slide {\n  from {\n    transform: translateX(0);\n  }\n  to {\n    transform: translateX(-100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Partnership_title__CceCu`,
	"subtitle": `Partnership_subtitle__jh0M5`,
	"partners": `Partnership_partners__djouE`,
	"partner": `Partnership_partner__RWoIj`,
	"slide": `Partnership_slide__pA-9S`,
	"partnersImage": `Partnership_partnersImage__zHRHl`
};
export default ___CSS_LOADER_EXPORT___;
