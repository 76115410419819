// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FooterAddress_container__XjWvD {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
  line-height: 24px;
  padding: 32px 0;
  width: 450px;
  height: 420px;
  color: white;
  background-color: #2d2d2d;
  border-top: 4px solid var(--color-primary);
}
.FooterAddress_container__XjWvD a {
  color: white;
  text-decoration: none;
}
.FooterAddress_container__XjWvD a:hover {
  text-decoration: underline;
}

.FooterAddress_socialIcon__HnBMw {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: #242424;
  display: flex;
  justify-content: center;
  align-items: center;
}
.FooterAddress_socialIcon__HnBMw:hover {
  background-color: var(--background-primary);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/FooterAddress.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;EACA,yBAAA;EACA,0CAAA;AACF;AACE;EACE,YAAA;EACA,qBAAA;AACJ;AAAI;EACE,0BAAA;AAEN;;AAGA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAF;AAEE;EACE,2CAAA;EACA,eAAA;AAAJ","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 16px;\n  text-align: center;\n  line-height: 24px;\n  padding: 32px 0;\n  width: 450px;\n  height: 420px;\n  color: white;\n  background-color: #2d2d2d;\n  border-top: 4px solid var(--color-primary);\n\n  a {\n    color: white;\n    text-decoration: none;\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n}\n\n.socialIcon {\n  width: 40px;\n  height: 40px;\n  margin-right: 8px;\n  border-radius: 50%;\n  background-color: #242424;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  &:hover {\n    background-color: var(--background-primary);\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FooterAddress_container__XjWvD`,
	"socialIcon": `FooterAddress_socialIcon__HnBMw`
};
export default ___CSS_LOADER_EXPORT___;
