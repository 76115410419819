// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFound_section__UOn6q {
  padding: 24px 0;
  text-align: center;
}

.NotFound_section__UOn6q .NotFound_error__d8xIY {
  font-size: 150px;
  color: var(--background-primary);
  text-shadow: 1px 1px 1px #70041b, 2px 2px 1px #6b041a, 3px 3px 1px #570c1c, 4px 4px 1px #520314, 5px 5px 1px #69091d, 6px 6px 1px #69091d, 7px 7px 1px #69091d, 8px 8px 1px #69091d, 25px 25px 8px rgba(0, 0, 0, 0.2);
}

.NotFound_page__YL-CA {
  margin: 32px 0;
  font-size: 20px;
  font-weight: 600;
}

.NotFound_backHome__G8czV {
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.2s linear;
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Errors/NotFound.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;EACA,gCAAA;EACA,qNAAA;AACF;;AAIA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AADF;;AAIA;EACE,yBAAA;EACA,gBAAA;EACA,2BAAA;EACA,YAAA;AADF","sourcesContent":[".section {\n  padding: 24px 0;\n  text-align: center;\n}\n\n.section .error {\n  font-size: 150px;\n  color: var(--background-primary);\n  text-shadow: 1px 1px 1px #70041b, 2px 2px 1px #6b041a, 3px 3px 1px #570c1c, 4px 4px 1px #520314,\n    5px 5px 1px #69091d, 6px 6px 1px #69091d, 7px 7px 1px #69091d, 8px 8px 1px #69091d,\n    25px 25px 8px rgba(0, 0, 0, 0.2);\n}\n\n.page {\n  margin: 32px 0;\n  font-size: 20px;\n  font-weight: 600;\n}\n\n.backHome {\n  text-transform: uppercase;\n  font-weight: 600;\n  transition: all 0.2s linear;\n  height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `NotFound_section__UOn6q`,
	"error": `NotFound_error__d8xIY`,
	"page": `NotFound_page__YL-CA`,
	"backHome": `NotFound_backHome__G8czV`
};
export default ___CSS_LOADER_EXPORT___;
