// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DropdownMenu_menuItem__a\\+xzt {
  border-bottom: 1px solid #f0f2f3 !important;
  padding: 20px !important;
  border-radius: 0 !important;
}
.DropdownMenu_menuItem__a\\+xzt:hover {
  color: #960524 !important;
}

.DropdownMenu_menuItemLabel__SMMTj {
  font-weight: bold !important;
}

.DropdownMenu_dropdownOverlay__3itbH {
  width: 250px;
  padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/DropdownMenu.module.scss"],"names":[],"mappings":"AAAA;EACE,2CAAA;EACA,wBAAA;EACA,2BAAA;AACF;AAAE;EACE,yBAAA;AAEJ;;AAEA;EACE,4BAAA;AACF;;AAEA;EACE,YAAA;EACA,qBAAA;AACF","sourcesContent":[".menuItem {\n  border-bottom: 1px solid #f0f2f3 !important;\n  padding: 20px !important;\n  border-radius: 0 !important;\n  &:hover {\n    color: #960524 !important;\n  }\n}\n\n.menuItemLabel {\n  font-weight: bold !important;\n}\n\n.dropdownOverlay {\n  width: 250px;\n  padding: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuItem": `DropdownMenu_menuItem__a+xzt`,
	"menuItemLabel": `DropdownMenu_menuItemLabel__SMMTj`,
	"dropdownOverlay": `DropdownMenu_dropdownOverlay__3itbH`
};
export default ___CSS_LOADER_EXPORT___;
