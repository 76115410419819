// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconCard_iconCardContainer__SSnCX {
  text-align: center;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  height: 470px;
}
.IconCard_iconCardContainer__SSnCX:hover {
  background: #960524;
  color: white;
  padding-top: 70px;
  transition: padding-top 0.3s ease-out 0s;
}
.IconCard_iconCardContainer__SSnCX:hover .IconCard_icon__li1Bh {
  background: white;
  color: #960524;
  box-shadow: -8px -8px #4c0c1a;
}

.IconCard_icon__li1Bh {
  width: 64px;
  height: 64px;
  background: #960524;
  color: white;
  transition: all 0.3s ease-out 0s;
  padding: 15px;
  box-shadow: -8px -8px #ebd1d4;
}

.IconCard_title__ccTNE {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 8px;
}

.IconCard_subtitle__Fl03F {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  font-style: italic;
}

.IconCard_description__omxnG {
  font-size: 14px;
  line-height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/Card/IconCard.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;EACA,gCAAA;EACA,yCAAA;EACA,aAAA;AACF;AACE;EACE,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,wCAAA;AACJ;AAAI;EACE,iBAAA;EACA,cAAA;EACA,6BAAA;AAEN;;AAGA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EACA,gCAAA;EACA,aAAA;EACA,6BAAA;AAAF;;AAGA;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AAAF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AACA;EACE,eAAA;EACA,iBAAA;AAEF","sourcesContent":[".iconCardContainer {\n  text-align: center;\n  padding: 80px 20px;\n  transition: all ease-in-out 0.3s;\n  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);\n  height: 470px;\n\n  &:hover {\n    background: #960524;\n    color: white;\n    padding-top: 70px;\n    transition: padding-top 0.3s ease-out 0s;\n    .icon {\n      background: white;\n      color: #960524;\n      box-shadow: -8px -8px #4c0c1a;\n    }\n  }\n}\n\n.icon {\n  width: 64px;\n  height: 64px;\n  background: #960524;\n  color: white;\n  transition: all 0.3s ease-out 0s;\n  padding: 15px;\n  box-shadow: -8px -8px #ebd1d4;\n}\n\n.title {\n  font-size: 28px;\n  font-weight: 600;\n  margin-bottom: 8px;\n}\n.subtitle {\n  font-size: 16px;\n  font-weight: 500;\n  margin-bottom: 16px;\n  font-style: italic;\n}\n.description {\n  font-size: 14px;\n  line-height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconCardContainer": `IconCard_iconCardContainer__SSnCX`,
	"icon": `IconCard_icon__li1Bh`,
	"title": `IconCard_title__ccTNE`,
	"subtitle": `IconCard_subtitle__Fl03F`,
	"description": `IconCard_description__omxnG`
};
export default ___CSS_LOADER_EXPORT___;
